<template>
  <div>
    <ManageCalendar />
  </div>
</template>
<script>
import ManageCalendar from "@/components/ManageCalendar/ManageCalendar";
export default {
  components: {
    ManageCalendar,
  },
  created() {},
};
</script>
